@import url("https://fonts.googleapis.com/css2?family=Oxygen&family=Lato&family=Vollkorn&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen&family=Lato&family=Vollkorn&display=swap");
.onboard-c0 {
  width: 100vw;
  height: 100vh;
  margin-top: -60px;
}
.onboard-c1-left {
  width: 35vw;
  padding-top: 75px;
  background-image: url("./../../images/bg/bg-pattern-third.svg");
  background-repeat: no-repeat;
  background-size: cover;
  justify-items: center;
  display: flex;
}

.onboard-timeline {
  vertical-align: center;
  margin: auto;
  text-align: center;
  display: flex;
}
.onboard-timeline-step {
  margin-bottom: 8px;
}
.onboard-c1-right {
  width: 65vw;
  padding-top: 75px;
  box-shadow: -3px 0px 6px;
  overflow: auto;
  justify-content: center;
}

.onboard-c1-left-title {
  padding-bottom: 1.8rem;
}

.onboard-c1-left-subtitle {
  line-height: 1.2rem;
  margin-bottom: 2rem;
}
.onboard-c1-left-img {
  float: bottom left;
  width: 15vw;
  margin-top: 2rem;
}
.onboard-c1-left-div {
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35%;
  position: relative;
}

.onboard-c1-right-div {
  margin-left: 10%;
  margin-right: 1rem;
  width: 90%;
  max-width: 500px;
}

div.onboard-button-div {
  float: bottom;
  justify-content: space-between;
  vertical-align: middle;
  height: 60px;
  margin-top: 1rem;
}

div.onboard-button {
  float: right;
  width: 100px;
}
div.onboard-button > button {
  width: 100%;
}

.onboard-c1-right-progress-bar {
  width: 100%;
  margin-left: 0px !important;
}

.onboard-loading-container {
  height: 80%;
  width: 100%;
  margin: auto;
  margin-top: 1rem;
  justify-content: center;
  text-align: center;
  align-items: center;
}

h4.onboard-loading-title {
  width: 300px;
  display: flex;
  margin-bottom: 2rem;
}

div.onboard-loading-subtitle {
  margin-top: 2rem;
}
div.onboard-loading-subtitle > span {
  font-size: 1.5rem;
  font-family: [ "Lato", "sans-serif" ];
}
h6.form-item-question {
  margin-bottom: 0rem;
}

span.form-item-description {
  padding-bottom: 1rem;
}

@media only screen and (max-width: 900px) {
  h6.form-item-question {
    font-size: 1rem;
    line-height: 1.2rem;
    padding-bottom: 0.2rem;
  }

  .onboard-c1-left {
    display: none !important;
  }
  .onboard-c0 {
    background-image: url("./../../images/bg/bg-pattern-third.svg");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;

    height: 100vh;
    overflow: hidden;
  }
  .onboard-c1-right {
    height: -webkit-fill-available;
    width: -webkit-fill-available;

    overflow: hidden;
    width: 100vh;
    padding: 2rem;
    box-shadow: -0.1em 0.1em 2em;
  }
  button {
    margin-left: 0;
  }
  .form-div {
    padding-top: 5vh;
  }
  .onboard-c1-right-div {
    width: 100%;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    overflow: hidden;
    display: flex;
    background-image: url("./../../images/bg/bg-pattern-full-light.svg");
    background-size: cover;
    border-radius: 0;
    background-repeat: no-repeat;
    margin: 0;
    position: fixed;
    top: 0;
  }
  div.form-item-container > .MuiInputBase-root {
    background-color: #f6f6f6;
  }
  .another-div {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100vh;
    justify-content: flex-start;
    width: 80%;
    overflow: hidden;
  }
  /* div.onboard-button-div {
    position: fixed;
    bottom: 1rem;
    align-self: center;
  }
  div.onboard-button-div > :first-child {
    margin-right: 2rem;
  }
  div.onboard-button-div > :last-child {
    margin-left: 2rem;
  } */
  .onboard-c1-right-div {
    max-width: 100%;
  }
}
