.reset-page-c1-left {
  width: 50%;
  background-image: url("./../../images/bg/bg-pattern-half.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  margin: auto;
}

.reset-page-c1-right {
  width: 50%;
  background-color: #f6f6f6;
  overflow-y: scroll;
}
h2.pass-form-title {
  margin-bottom: 1rem;
}

.reset-page-c0 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.reset-page-c1-right-content {
  width: 90%;
  max-width: 420px;
  margin: auto;
}
.reset-page-c1-left-shadow {
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 9;
  top: 50;
  left: 0;
  box-shadow: inset -2px 2px 4px black;
}
.reset-timeline {
  max-width: 70%;
  margin: auto;
}
h5.reset-timeline-title {
  padding-bottom: 2rem;
  font-size: 1.9rem;
}
.reset-timeline-step {
  margin-bottom: 0.25rem;
  vertical-align: center;
  align-content: center;
  vertical-align: middle;
}
span.reset-disclaimer-text {
  float: bottom left;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
p.reset-timeline-step-text {
  font-size: 1.2rem;
  align-self: baseline;
  justify-items: flex-end;
}
.reset-timeline-step-number {
  width: 4rem;
  margin-right: 2rem;
}

.reset-form > button {
  margin-bottom: 1rem;
}
.reset-or-horizontal-line {
  height: 1px;
  width: 100%;
  background-color: #c6cacc;
  vertical-align: center;
  align-self: center;
  justify-items: center;
  margin: auto;
}

span.reset-or {
  display: flex;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.reset-or-container {
  margin-bottom: 1rem;
}

button.google-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}
button.apple-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}

span.reset-phone-explainer {
  position: relative;
  top: -1rem;
  font-weight: 500;
  color: #6d6d6d;
  font-size: 0.75rem;
}
@media only screen and (max-width: 800px) {
  .reset-page-c1-right {
    width: 100%;
    height: 100%;
  }
  .reset-page-c1-left {
    display: none;
  }
  .reset-page-c1-left-shadow {
    display: none;
  }
}