@import url(https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen&family=Lato&family=Vollkorn&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen&family=Lato&family=Vollkorn&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen&family=Lato&family=Vollkorn&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oxygen&family=Lato&family=Vollkorn&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-c0 {
  width: 100%;
  justify-content: space-between;
  margin: auto;
  min-height: 100vh;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.home-c1 {
  width: 100%;
  max-width: 650px;
  margin-left: 4rem;
  margin-right: -2rem;
  justify-content: center;
  background-color: transparent;
}
h2.home-title {
  margin-bottom: 2rem;
  text-align: left;
  line-height: 3rem;
  font-size: 3rem;
  font-weight: 400;
}

span.home-web-button-text {
  font-size: 1rem;
}
p.home-subtitle-lower {
  text-align: left;
  font-size: 1.1rem;
  margin-bottom: 2rem;
  line-height: 1.4rem;
}
p.home-subtitle-upper {
  text-align: left;
  font-size: 0.8rem;
  margin-bottom: 3rem;
}

div.home-c1.row-container > div > div > div > fieldset {
  border-color: #283596;
}

span.home-mobile-button-text {
  display: none;
}
.home-label {
  padding-bottom: 4vh;
}
img.home-ben {
  height: auto;
  max-width: 40%;
  align-self: flex-end;
  z-index: -5;
  bottom: 0;
  margin-left: auto;
}
span.teal-highlight {
  background-position-y: 95% !important;
}
.home-c1 > div.embedded-email-input-container {
  margin-bottom: 2rem;
}

div.home-c1.row-container > div > div > div > fieldset > legend > span {
  font-size: 0.9rem;
}
p.qualifier-text {
  line-height: 1.6rem;
}
span.qualifier-label {
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 800px) {
  span.teal-highlight {
    background-position-y: 95% !important;
    background-size: auto 20%;
  }
  div.home-c1 {
    margin: auto;
    width: 90%;
  }
  div.home-c0 {
    padding-top: 7rem;
    flex-direction: column;
    height: auto;
    overflow-y: scroll;
  }

  h2.home-title {
    text-align: center;
    font-size: 2rem;
    line-height: 2.2rem;
  }
  p.home-subtitle-lower {
    text-align: center;
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .home-ben {
    margin: auto;
    margin-top: 2rem;
    min-width: 100%;
  }
  span.home-web-button-text {
    display: none;
  }
  span.home-mobile-button-text {
    display: block;
    font-size: 1rem;
  }
}

.process-container {
  width: 100%;
  justify-content: space-between;
  margin: auto;
  min-height: 100vh;
  position: absolute;
  top: 100vh;
  overflow: hidden;
}

.page-root {
  height: 100vh;
  width: 100vw;
}

.column-container {
  display: flex;
  flex-direction: row;
}

.row-container {
  display: flex;
  flex-direction: column;
}

span.teal-highlight {
  background-image: url(/static/media/teal-highlight.5bed2410.svg);
  background-position: left bottom;
  background-repeat: no-repeat;
}
#menu-
  > div.MuiPaper-root-1121.MuiMenu-paper-1041.MuiPopover-paper-1044.MuiPaper-rounded-1122
  > ul
  > li {
  line-height: 3rem;
  color: #6d6d6d;
}

.home-title span.word-highlight {
  border-bottom: 1px solid #d8dbe8;
  box-shadow: 0px -10px 0px #d8dbe8 inset;
}

div.header {
  height: 4rem;
  background-color: white;
}

body {
  max-width: 100%;
  background-color: #ffffff;
  overflow-x: hidden;
}

.header-c0 {
  background-color: #283596;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 3px;
  top: 0;
  position: relative;
  width: 100vw;
  z-index: 100;
}
.header-logo {
  margin-top: 10px;
  height: 50px;
  width: auto;
  margin-left: 2vw;
}

.header-button-container {
  height: 100%;
  display: flex; 
  align-items: center;
}

.header-button-container > button{
  height: 60%;
  margin-right: 2vw;
  padding-left: 30px;
  padding-right: 30px;
}

.username-button {
  border: none !important;
  font-size: 15pt !important;
}
.contact-button {
  border: none !important;
}
@media only screen and (max-width: 750px) {
  .header-button-container > button{
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 750px) {
  .header-button-container > button{
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
    font-size: 10pt !important;
  }
  .header-sign-out-button{
    display: none !important;
  }
}

.single-select-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

button.single-select-button {
  background-color: #ffffff;
  border: 1px solid #c6cacc;
  font-weight: normal;
  width: 100%;
  color: #6d6d6d;
  margin-left: 5px;
  margin-right: 5px;
  /* margin-left: 20px;
  margin-right: 20px; */
}
.form-container > .form-item-container {
  margin-bottom: 0rem;
}
button.single-select-button:hover {
  background-color: #d8dbe8;
}
button.selected {
  background-color: #d8dbe8;
  border: 1px solid #283593;
  font-weight: normal;
  color: #6d6d6d;
}
h6.form-item-question {
  line-height: 1.6rem;
  margin-bottom: 1rem;
}

span.form-item-description {
  font-size: 0.8rem;
  line-height: 1.1rem;
  padding-top: 0.25rem;
  padding-bottom: 1.2rem;
}
.form-item-container {
  padding-bottom: 2rem;
}
.double-input-container {
  width: 100%;
}

div.double-input-container > *:first-child {
  margin-right: 0.5rem;
  flex: 1 1;
}
div.double-input-container > *:last-child {
  margin-left: 0.5rem;
  flex: 1 1;
}
div.account-page-c1-right
  > div
  > div.MuiPaper-root.MuiCard-root.account-page-card.referral-card.MuiPaper-rounded
  > div
  > div
  > div
  > div.referral-card-email-container.column-container
  > div
  > label {
  top: 0;
  font-size: 0.875rem;
}

h2.form-title {
  padding-bottom: 3vh;
  font-size: 2.5em;
  height: auto;
}
div.form-item-container.column-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

div.form-item-container.column-container > * {
  width: 50%;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
div.form-item-container.column-container > *:only-child {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}
div.form-item-container.column-container > *:first-child {
  margin-right: 0.5rem;
  margin-left: 0rem;
}
div.form-item-container.column-container > *:last-child {
  margin-left: 0.5rem;
  margin-right: 0rem;
}

div.form-item-container > .form-item-text-field {
  width: 100%;
  background-color: white;
}
div.form-container {
  width: 100%;
}

@media only screen and (max-width: 900px) {
  button.single-select-button {
    height: 50px;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .single-select-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .single-select-button-container > button:first-child {
    margin-right: 0px;
    margin-left: 0px;
  }
  .single-select-button-container > button:last-child {
    margin-left: 0px;
    margin-right: 0px;
  }
  .form-title {
    height: 10vh;
  }
}

.embedded-email-input-container > * {
  align-self: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.process-c0 {
  background-color: #f6f6f6;
  width: 100vw;
  height: 100vh;
}
.process-c1 {
  margin: auto;
  text-align: center;
  height: 75%;
  width: 80%;
}
.process-card {
  margin: auto;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.process-card-text {
  width: 50%;
}
h2.process-title {
  margin-bottom: 3rem;
}

.onboard-c0 {
  width: 100vw;
  height: 100vh;
  margin-top: -60px;
}
.onboard-c1-left {
  width: 35vw;
  padding-top: 75px;
  background-image: url(/static/media/bg-pattern-third.9dc911cf.svg);
  background-repeat: no-repeat;
  background-size: cover;
  justify-items: center;
  display: flex;
}

.onboard-timeline {
  vertical-align: center;
  margin: auto;
  text-align: center;
  display: flex;
}
.onboard-timeline-step {
  margin-bottom: 8px;
}
.onboard-c1-right {
  width: 65vw;
  padding-top: 75px;
  box-shadow: -3px 0px 6px;
  overflow: auto;
  justify-content: center;
}

.onboard-c1-left-title {
  padding-bottom: 1.8rem;
}

.onboard-c1-left-subtitle {
  line-height: 1.2rem;
  margin-bottom: 2rem;
}
.onboard-c1-left-img {
  float: bottom left;
  width: 15vw;
  margin-top: 2rem;
}
.onboard-c1-left-div {
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35%;
  position: relative;
}

.onboard-c1-right-div {
  margin-left: 10%;
  margin-right: 1rem;
  width: 90%;
  max-width: 500px;
}

div.onboard-button-div {
  float: bottom;
  justify-content: space-between;
  vertical-align: middle;
  height: 60px;
  margin-top: 1rem;
}

div.onboard-button {
  float: right;
  width: 100px;
}
div.onboard-button > button {
  width: 100%;
}

.onboard-c1-right-progress-bar {
  width: 100%;
  margin-left: 0px !important;
}

.onboard-loading-container {
  height: 80%;
  width: 100%;
  margin: auto;
  margin-top: 1rem;
  justify-content: center;
  text-align: center;
  align-items: center;
}

h4.onboard-loading-title {
  width: 300px;
  display: flex;
  margin-bottom: 2rem;
}

div.onboard-loading-subtitle {
  margin-top: 2rem;
}
div.onboard-loading-subtitle > span {
  font-size: 1.5rem;
  font-family: [ "Lato", "sans-serif" ];
}
h6.form-item-question {
  margin-bottom: 0rem;
}

span.form-item-description {
  padding-bottom: 1rem;
}

@media only screen and (max-width: 900px) {
  h6.form-item-question {
    font-size: 1rem;
    line-height: 1.2rem;
    padding-bottom: 0.2rem;
  }

  .onboard-c1-left {
    display: none !important;
  }
  .onboard-c0 {
    background-image: url(/static/media/bg-pattern-third.9dc911cf.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;

    height: 100vh;
    overflow: hidden;
  }
  .onboard-c1-right {
    height: -webkit-fill-available;
    width: -webkit-fill-available;

    overflow: hidden;
    width: 100vh;
    padding: 2rem;
    box-shadow: -0.1em 0.1em 2em;
  }
  button {
    margin-left: 0;
  }
  .form-div {
    padding-top: 5vh;
  }
  .onboard-c1-right-div {
    width: 100%;
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    overflow: hidden;
    display: flex;
    background-image: url(/static/media/bg-pattern-full-light.9d9778d7.svg);
    background-size: cover;
    border-radius: 0;
    background-repeat: no-repeat;
    margin: 0;
    position: fixed;
    top: 0;
  }
  div.form-item-container > .MuiInputBase-root {
    background-color: #f6f6f6;
  }
  .another-div {
    display: flex;
    flex-direction: column;
    margin: auto;
    height: 100vh;
    justify-content: flex-start;
    width: 80%;
    overflow: hidden;
  }
  /* div.onboard-button-div {
    position: fixed;
    bottom: 1rem;
    align-self: center;
  }
  div.onboard-button-div > :first-child {
    margin-right: 2rem;
  }
  div.onboard-button-div > :last-child {
    margin-left: 2rem;
  } */
  .onboard-c1-right-div {
    max-width: 100%;
  }
}

.outer-container-error {
  max-width: 500px;
  max-height: 400px;
  align-items: center;
  margin-top:auto;
  margin-bottom:auto;
}
.outer-container {
  width: 80vw;
  padding: 5rem;
  margin-top: 10vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-self: center;
}

@media only screen and (max-width: 750px) {
  #outer-container-mobile {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
.root {
  background-color: #f6f6f6;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 10vh;
  background-color: #f6f6f6;
  margin-bottom: 5vh;
}

.underline-highlight {
  border-bottom: solid 10px #7282d3;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}

.signin-page-c1-left {
  width: 50%;
  background-image: url(/static/media/bg-pattern-half.f1e5d623.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  margin: auto;
}

.signin-page-c1-right {
  width: 50%;
  background-color: #f6f6f6;
  overflow-y: scroll;
}

.signin-page-c0 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

}
.signin-page-c1-right-content {
  width: 90%;
  max-width: 420px;
  margin: auto;
}
.signin-page-c1-left-shadow {
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 9;
  top: 50;
  left: 0;
  box-shadow: inset -2px 2px 4px black;
}
.signin-timeline {
  max-width: 70%;
  margin: auto;
}
h5.signin-timeline-title {
  padding-bottom: 2rem;
  font-size: 1.9rem;
}
.signin-timeline-step {
  margin-bottom: 0.25rem;
  vertical-align: center;
  align-content: center;
  vertical-align: middle;
}
span.signin-disclaimer-text {
  float: bottom left;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
p.signin-timeline-step-text {
  font-size: 1.2rem;
  align-self: baseline;
  justify-items: flex-end;
}
.signin-timeline-step-number {
  width: 4rem;
  margin-right: 2rem;
}

.signin-form > button {
  margin-bottom: 1rem;
}
.signin-or-horizontal-line {
  height: 1px;
  width: 100%;
  background-color: #c6cacc;
  vertical-align: center;
  align-self: center;
  justify-items: center;
  margin: auto;
}

span.signin-or {
  display: flex;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.signin-or-container {
  margin-bottom: 1rem;
}

button.google-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}
button.apple-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}

span.signin-phone-explainer {
  position: relative;
  top: -1rem;
  font-weight: 500;
  color: #6d6d6d;
  font-size: 0.75rem;
}
.reset-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
  margin-right: 1vw;
}

.set-up-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
  margin-left: 1vw;
}
@media only screen and (max-width: 800px) {
  .signin-page-c1-right {
    width: 100%;
    height: 100%;
  }
  .signin-page-c1-left {
    display: none;
  }
  .signin-page-c1-left-shadow {
    display: none;
  }
}

span.sign-in-forgot-pass-text {
  color: #283593;
  margin-top: -0.75rem;
  padding-bottom: 1rem;
  text-align: right;
}

p.sign-in-sign-up-text {
  padding-top: 0.5rem;
  font-size: 0.9rem;
}

@media only screen and (max-width: 1000px) {
  p.join-timeline-step-text {
    font-size: 0.9rem !important;
  }
}

.join-timeline {
  max-width: 70%;
  margin: auto;
}
h5.join-timeline-title {
  padding-bottom: 2rem;
  font-size: 1.9rem;
}
div.join-timeline-step-text-div {
  min-height: 4rem;
  display: flex;
  align-items: center;
  /* background-color: blue; */
}
p.join-timeline-step-text {
  font-size: 1.2rem;

  /* background-color: red; */
}
.join-timeline-step-number {
  width: 4rem;
  margin-right: 2rem;
  padding-bottom: 3px;
}

.join-timeline-text-container {
  min-height: 100%;
  justify-content: space-between;
  /* background-color: yellow; */
  justify-self: flex-start;
  padding: 0;
}

.join-timeline-text-content {
}

.join-timeline-number-container {
  justify-content: space-between;
}

.reset-page-c1-left {
  width: 50%;
  background-image: url(/static/media/bg-pattern-half.f1e5d623.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  margin: auto;
}

.reset-page-c1-right {
  width: 50%;
  background-color: #f6f6f6;
  overflow-y: scroll;
}
h2.pass-form-title {
  margin-bottom: 1rem;
}

.reset-page-c0 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.reset-page-c1-right-content {
  width: 90%;
  max-width: 420px;
  margin: auto;
}
.reset-page-c1-left-shadow {
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 9;
  top: 50;
  left: 0;
  box-shadow: inset -2px 2px 4px black;
}
.reset-timeline {
  max-width: 70%;
  margin: auto;
}
h5.reset-timeline-title {
  padding-bottom: 2rem;
  font-size: 1.9rem;
}
.reset-timeline-step {
  margin-bottom: 0.25rem;
  vertical-align: center;
  align-content: center;
  vertical-align: middle;
}
span.reset-disclaimer-text {
  float: bottom left;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
p.reset-timeline-step-text {
  font-size: 1.2rem;
  align-self: baseline;
  justify-items: flex-end;
}
.reset-timeline-step-number {
  width: 4rem;
  margin-right: 2rem;
}

.reset-form > button {
  margin-bottom: 1rem;
}
.reset-or-horizontal-line {
  height: 1px;
  width: 100%;
  background-color: #c6cacc;
  vertical-align: center;
  align-self: center;
  justify-items: center;
  margin: auto;
}

span.reset-or {
  display: flex;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.reset-or-container {
  margin-bottom: 1rem;
}

button.google-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}
button.apple-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}

span.reset-phone-explainer {
  position: relative;
  top: -1rem;
  font-weight: 500;
  color: #6d6d6d;
  font-size: 0.75rem;
}
@media only screen and (max-width: 800px) {
  .reset-page-c1-right {
    width: 100%;
    height: 100%;
  }
  .reset-page-c1-left {
    display: none;
  }
  .reset-page-c1-left-shadow {
    display: none;
  }
}
.change-page-c1-left {
  width: 50%;
  background-image: url(/static/media/bg-pattern-half.f1e5d623.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  margin: auto;
}

.change-page-c1-right {
  width: 100%;
  background-color: #f6f6f6;
  overflow-y: scroll;
}
h2.pass-form-title {
  margin-bottom: 1rem;
}

.change-page-c0 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.change-page-c1-right-content {
  width: 90%;
  max-width: 420px;
  margin: auto;
}
.change-page-c1-left-shadow {
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 9;
  top: 50;
  left: 0;
  box-shadow: inset -2px 2px 4px black;
}
.change-timeline {
  max-width: 70%;
  margin: auto;
}
h5.change-timeline-title {
  padding-bottom: 2rem;
  font-size: 1.9rem;
}
.change-timeline-step {
  margin-bottom: 0.25rem;
  vertical-align: center;
  align-content: center;
  vertical-align: middle;
}
span.change-disclaimer-text {
  float: bottom left;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
p.change-timeline-step-text {
  font-size: 1.2rem;
  align-self: baseline;
  justify-items: flex-end;
}
.change-timeline-step-number {
  width: 4rem;
  margin-right: 2rem;
}

.change-form > button {
  margin-bottom: 1rem;
}
.change-or-horizontal-line {
  height: 1px;
  width: 100%;
  background-color: #c6cacc;
  vertical-align: center;
  align-self: center;
  justify-items: center;
  margin: auto;
}

span.change-or {
  display: flex;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.change-or-container {
  margin-bottom: 1rem;
}

button.google-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}
button.apple-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}

span.change-phone-explainer {
  position: relative;
  top: -1rem;
  font-weight: 500;
  color: #6d6d6d;
  font-size: 0.75rem;
}
@media only screen and (max-width: 800px) {
  .change-page-c1-right {
    width: 100%;
    height: 100%;
  }
  .change-page-c1-left {
    display: none;
  }
  .change-page-c1-left-shadow {
    display: none;
  }
}
.contact-page-c1-left {
  width: 50%;
  background-image: url(/static/media/bg-pattern-half.f1e5d623.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  margin: auto;
}

.contact-page-c1-right {
  width: 100%;
  background-color: #f6f6f6;
  overflow-y: scroll;
}
h2.pass-form-title {
  margin-bottom: 1rem;
}

.contact-page-c0 {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.contact-page-c1-right-content {
  width: 90%;
  max-width: 500px;
  margin: auto;
}
.contact-page-c1-left-shadow {
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 9;
  top: 50;
  left: 0;
  box-shadow: inset -2px 2px 4px black;
}
.contact-timeline {
  max-width: 70%;
  margin: auto;
}
h5.contact-timeline-title {
  padding-bottom: 2rem;
  font-size: 1.9rem;
}
.contact-timeline-step {
  margin-bottom: 0.25rem;
  vertical-align: center;
  align-content: center;
  vertical-align: middle;
}
span.contact-disclaimer-text {
  float: bottom left;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
p.contact-timeline-step-text {
  font-size: 1.2rem;
  align-self: baseline;
  justify-items: flex-end;
}
.contact-timeline-step-number {
  width: 4rem;
  margin-right: 2rem;
}

.contact-form > button {
  margin-bottom: 1rem;
}
.contact-or-horizontal-line {
  height: 1px;
  width: 100%;
  background-color: #c6cacc;
  vertical-align: center;
  align-self: center;
  justify-items: center;
  margin: auto;
}

span.contact-or {
  display: flex;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.contact-or-container {
  margin-bottom: 1rem;
}

button.google-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}
button.contact-sign-button {
  width: 75%;
  margin-left:auto;
  margin-right:auto;
}

span.contact-phone-explainer {
  position: relative;
  top: -1rem;
  font-weight: 500;
  color: #6d6d6d;
  font-size: 0.75rem;
}
@media only screen and (max-width: 800px) {
  .contact-page-c1-right {
    width: 100%;
    height: 100%;
  }
  .contact-page-c1-left {
    display: none;
  }
  .contact-page-c1-left-shadow {
    display: none;
  }
}
.join-page-c1-left {
  width: 50vw;
  background-image: url(/static/media/bg-pattern-half.f1e5d623.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.join-page-c1-right {
  width: 50%;
  background-color: #f6f6f6;
  height: auto;
  padding-top: 75px;
}

.join-page-c0 {
  width: 100vw;
  min-height: 100vh;
  margin-top: -50px;
}
.join-page-c1-right-content {
  width: 90%;
  max-width: 420px;
  margin: auto;
}

div.join-form > div.form-item-container > div.form-item-text-field {
  padding-left: 0px !important;
}

.join-page-c1-left-shadow {
  position: absolute;
  width: 50vw;
  z-index: 9;
  top: 50;
  left: 0;
  box-shadow: inset -2px 2px 4px black;
}
span.join-disclaimer-text {
  float: bottom left;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
.join-loading-subtitle > span {
  font-size: 1.5rem;
  font-family: [ "Lato", "sans-serif" ];
}

.join-loading-subtitle {
  margin-top: 2rem;
  font-size: 1.5rem;
}

.join-form > button {
  margin-bottom: 1rem;
}

.join-or-horizontal-line {
  height: 1px;
  width: 100%;
  background-color: #c6cacc;
  vertical-align: center;
  align-self: center;
  justify-items: center;
  margin: auto;
}

span.join-or {
  display: flex;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
h2.form-container {
  padding-top: 30px;
}
.join-or-container {
  margin-bottom: 1rem;
}

button.google-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}
button.apple-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}

span.join-phone-explainer {
  position: relative;
  top: -2rem;
  font-weight: 500;
  color: #6d6d6d;
  font-size: 0.75rem;
}
.join-form > .early-input {
  padding-bottom: 1rem;
}
.join-form > .form-item-container {
  padding-bottom: 2rem;
}

@media only screen and (max-width: 800px) {
  .join-page-c1-left {
    display: none;
  }
  .join-page-c1-right {
    width: 100%;
  }
  .join-page-c1-left-shadow {
    display: none;
  }
  h2.teal-highlight {
    margin-left: 20px;
  }
}

button.google-sign-button {
  margin-bottom: 1.4rem;
}

span.join-sign-in-button {
  text-decoration: underline;
  color: #283593;
  font-weight: 600;
}

p.join-sign-in-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
}

h1.refund-amount {
  background: linear-gradient(to right, #5f5fc4, 50%, #001064);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 8vw;
}
h6.onboard-complete-title {
  background: linear-gradient(to right, #5f5fc4, 50%, #001064);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2vw;
}

button.onboard-complete-apply-button {
  background-image: linear-gradient(to right, #5f5fc4, 50%, #001064);
  color: white;
  font-size: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 5rem;
  width: 70%;
}
.onboard-complete-c1-breakdown {
  width: 60%;
  justify-content: flex-start;
}
.onboard-complete-c1-content {
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: space-between;
}
h6.onboard-complete-apply-text {
  width: 70%;
  font-size: 1rem;
  text-align: center;
}

.onboard-complete-c1 {
  width: 70%;
  margin: auto;
  margin-bottom: 4rem;
  padding-bottom: 6rem;
  background-color: #f6f6f6;
  box-shadow: -0.3rem 0.3rem 2rem black;
  margin-top: 8rem;
  padding-top: 5rem;
  text-align: center;
}

p.refund-tooltip {
  font-size: 1rem;
}

.onboard-complete-c0-top {
  margin: auto;
  background-image: url(/static/media/bg-pattern-double.6e00a5ab.svg);
  background-size: cover;
  background-position: top;
  display: flex;
  z-index: -2;
}
.onboard-complete-c0-bottom {
  margin: auto;
  text-align: center;
  background-color: #f6f6f6;
  width: 100%;
}
.onboard-complete-c0-bottom-content {
  max-width: 55%;
  margin: auto;
  padding-bottom: 5rem;
}

p.refund-breakdown-row-amount {
  font-weight: 100;
  width: 100%;
  font-size: 2rem;
}

p.refund-label-row {
  font-weight: 1000;
  font-size: 2em;
  width: 100%;
}
.refund-breakdown-row-c0 {
  width: 100%;
  text-align: right;
  margin-bottom: 1.5rem;
  z-index: 9;
}

a.refund-breakdown-row-label {
  padding-top: 0.3rem;
  text-decoration: underline;
  z-index: 999;
}

.refund-breakdown-row-c1 {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #4056a1;
}
.refund-breakdown-c0 {
  width: 50%;
  margin: auto;
  margin-top: 6rem;
}
.onboard-complete-card-1-content {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, #5f5fc4, 50%, #001064);
}
.refund-card-button-text {
  vertical-align: middle;
}

h4.refund-card-button-text {
  font-weight: 500;
  text-align: center;
  align-self: center;
  font-size: 1.8vw;
}
p.refund-card-button-subtext {
  padding-top: 1rem;
  font-size: 1vw;
  text-align: left;
  align-self: center;
}

.onboard-complete-card-3-content {
  width: 100%;
  background-image: url(/static/media/card-3.4d3215c5.svg);
  background-color: #f6f6f6;
  background-repeat: none;
  background-position: fit;
}

.onboard-complete-card-2-content {
  width: 100%;
  background-image: url(/static/media/card-2.9a193b3b.svg);
  background-color: #f6f6f6;
  background-repeat: none;
  background-position: fit;
}
div.refund-card-text {
  padding-top: 2rem;
}
.onboard-complete-card {
  display: flex;
  max-width: 300px;
  height: 200px;
  margin-bottom: 2rem;
  margin-right: 0.5rem;
  border: 1px solid #c3c3c3;
}

div.onboard-complete-card-container {
  width: 40%;
  min-height: 100%;
  justify-content: flex-start;
  z-index: 10;
}
h6.onboard-complete-fee-text {
  margin-top: 1rem;
  line-height: 120%;
}
.onboard-complete-fee-text-container {
  max-width: 80%;
  text-align: center;
  margin: auto;
}

.onboard-complete-help-button-container {
  height: 100vh;
  width: 20vw;
  z-index: 2;

  position: absolute;
  top: 0px;
  left: 80vw;
}
.onboard-complete-help-button {
  margin: auto;
  position: absolute;
  bottom: 20vh;
  left: 0%;
  border-radius: 50%;
  width: 20vw;
  height: 20vw;
  align-items: center;
}
.onboard-complete-card-mobile {
  display: none;
}

h6.onboard-complete-help-text {
  /* background: linear-gradient(to right, #5f5fc4, 50%, #001064); */
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding-bottom: 10px;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  padding-top: 50px;
}

img.onboard-complete-help-arrow {
  width: 75px;
  cursor: pointer;
}
.refund-subtext-mobile {
  display: none;
}

.refund-breakdown-highlight-red > *:first-child > *:first-child > *:last-child {
  background-image: url(/static/media/red-highlight.d42dadae.svg);
  background-position: right;
  background-repeat: no-repeat;
}
.refund-breakdown-highlight-green
  > *:first-child
  > *:first-child
  > *:last-child {
  background-image: url(/static/media/green-highlight.1b448b85.svg);
  background-position: right;
  background-repeat: no-repeat;
}

.refund-card-button-icon {
  height: 3vh !important;
  align-self: center;
  color: white;
}

.onboard-complete-footer {
  width: 100%;
  background-color: #f6f6f6;
  vertical-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
}

@media only screen and (max-width: 750px) {
  .onboard-complete-c1 {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: none;
    padding-bottom: 0px;
  }
  .refund-breakdown-c0 {
    margin-top: 4rem;
  }
  div.onboard-complete-card-1-content {
    width: auto;
    height: auto;
    font-size: 3.5vw;
    padding: 16px !important;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
  }
  .onboard-complete-card-2-content {
    width: auto;
    background-size: cover;
    height: 200px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .onboard-complete-card-3-content {
    background-image: none;
    max-width: 300px;
    width: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .refund-card-button-subtext-mobile {
    width: 100%;
    justify-items: center;
    display: flex;
  }
  p.refund-card-button-subtext-mobile {
    width: 80%;
    margin: auto;
    padding-top: 1.5rem;

    font-size: 1rem;
  }
  h4.refund-card-button-text {
    font-size: 3vw;
  }
  
  .onboard-complete-help-button-container {
    display: block;
  }
  .onboard-complete-footer {
    background-color: #ffffff;
  }
  h1.refund-amount {
    font-size: 4rem;
    padding-bottom: 3rem;
  }
  .refund-breakdown-row-c1.column-container {
    flex-direction: row;
  }
  .onboard-complete-apply-button {
    font-size: 4rem;
    padding: 0.5rem;
    margin-top: 3rem;
    height: auto;
  }
  h6.onboard-complete-title {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 5vw;
    padding-top: 3rem;
  }
  p.refund-card-button-subtext {
    display: none;
  }
  .help-button-icon {
    max-height: 1rem;
    align-self: center;
    margin-bottom: -0.1rem;
    padding: 0px;
  }
  .refund-breakdown-c0 {
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0rem;
  }
  .onboard-complete-card-mobile {
    display: block;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f6f6 !important;
  }
  .onboard-complete-c1-breakdown {
    width: 100%;
  }

  .onboard-complete-card-container {
    width: 0px;
    display: none;
  }
  .onboard-complete-footer {
    display: none;
  }
}

.refund-card-button-content {
  max-width: 90%;
}

p.disabled-card-title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
  opacity: 0.2;
}

.disabled-card-icon {
  opacity: 0.2;
  max-width: 40px;
}
.disabled-card-image {
  opacity: 0.2;
  height: 120px;
  margin-right: 1rem;
  box-shadow: -1px 1px 3px gray;
}

.account-page-card-icon {
  height: 40px;
  width: 40px;
  margin-right: 2rem;
}

.referral-card-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 1rem;
}
.card-content {
  width: 100%;
}

div.placeholder-half {
  width: 150px;
  height: 100%;
  margin-right: 2rem;
}
.skeleton-half {
  margin-bottom: 0.5rem;
  font-size: 0.5rem;
}
.WAMuiChipInput-inputRoot-2 {
}
div.placeholder-full {
  width: 100%;
  height: 100%;
  margin-left: 2rem;
  margin-right: 2rem;
}
.skeleton-full {
  margin-bottom: 0.5rem;
  font-size: 0.5rem;
}
.invite-card-input-container {
  margin-right: 1rem;
  justify-content: space-between;
}
h6.invite-card-title {
  line-height: 1.7rem;
}

.invite-card-button-container {
  min-width: 40%;
}
.invite-card-button-container > button:first-child {
  flex: 1 1;
  margin-right: 0.25rem;
}
.invite-card-button-container > button:last-child {
  flex: 1 1;
  margin-left: 0.25rem;
}
.invite-card-email-container {
  min-width: 60%;
  justify-content: space-between;
  margin-right: 1rem;
  align-items: stretch;
}

.invite-card-email-container > *:first-child {
  flex: 1 1;
  margin-right: 0.5rem;
}

.invite-card-c1 {
  margin-left: 2rem;
}

.invite-card-c0 {
  padding-top: 1rem;
  padding-top: 1rem;

  align-items: flex-start;
}

@media only screen and (max-width: 800px) {
  div.invite-card-email-container {
    display: none;
  }

  div.invite-card-c0 {
    flex-direction: column;
    justify-items: flex-start;
  }
  img.invite-card-icon {
    margin-bottom: 0.5rem;
  }
  div.invite-card-c1 {
    margin-left: 0rem;
  }
  div.invite-card-button-container {
    width: 100%;
  }
}

.invite-card-icon {
  width: 40px;
}
.invite-button-icon {
  padding-right: 5px;
}
span.invite-card-subtitle {
  margin-bottom: 1rem;
  line-height: 1rem;
}

.account-page-c0 {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}
.account-page-c1-left {
  background-image: url(/static/media/bg-gradient-third.52596fae.svg);
  width: 30vw;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.account-page-c1-right {
  background-image: url(/static/media/bg-pattern-full-light.9d9778d7.svg);
  width: 70vw;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;

  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.button-icon {
  padding-left: 5px;
}
h3.welcome-text {
  font-weight: 400;
  vertical-align: center;
}

h3.welcome-text > span > span {
  padding-bottom: 0.3rem;
}

.mobile-welcome-card-content {
  background-image: linear-gradient(to right, #5f5fc4, 50%, #001064);
  height: 100%;
  width: 100%;
}

.account-page-c1-left-shadow {
  position: absolute;
  min-height: 100vh;
  width: 30vw;
  z-index: 9;
  top: 50;
  left: 0;
  box-shadow: inset -2px 2px 4px black;
}
.account-page-c1-left-content {
  width: 90%;
  margin: auto;
  height: 100%;
  margin-bottom: 0;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.review-card {
  border: 1px solid #444e9e;
}
p.review-card-text {
  color: #444e9e;
  font-size: 0.9rem;
  line-height: 1.15rem;
}
.referral-card-email-container {
  width: 70%;
  margin-right: 10px;
}
.referral-card-email-field > *:last-child {
  height: 100%;
}
.referral-card-email-field {
  width: 65%;
}

.referral-card-content-text > span {
  line-height: 1rem;
  letter-spacing: 80%;
  margin-bottom: 2rem;
}
.referral-card-btn-container {
  height: 50px;
}
.referral-card-btns-right {
  width: 40%;
  margin-left: 0;
  justify-content: space-between;
}

h3.welcome-text {
  margin-bottom: 2em;
  z-index: 10;
}
.account-page-c1-right-content {
  width: 80%;
  margin: auto;
  justify-content: space-between;
}
.account-page-card-icon {
  height: 40px;
  width: 40px;
  margin-right: 2rem;
}

.placeholder-text {
  width: 100%;
}

.account-page-card {
  box-shadow: none;
  border: 1px solid #c4c4c4;
  margin-left: 0.8rem;
  margin-right: 1rem;
  margin-bottom: 0.75rem;
}
.referral-card-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.review-card {
  border: 1px solid #444e9e;
}
.disabled-card-content {
  opacity: 0.2;
}

.referral-card-email-container {
  width: 70%;
  margin-right: 10px;
}
.referral-card-email-field > *:last-child {
  height: 100%;
}
.referral-card-email-field {
  width: 65%;
}

button > * > img {
  padding: 10px;
}

button.referral-button {
  border: 1px solid #ececec;

  width: 100%;
  margin-left: 2px;
}

button.send-invites-button {
  min-width: 35%;
  margin-left: 0.2rem;
}
.referral-card-content-text > span {
  line-height: 1rem;
  letter-spacing: 80%;
  margin-bottom: 2rem;
}
.referral-card-btn-container {
  height: 50px;
}
.referral-card-btns-right {
  width: 40%;
  margin-left: 0;
  justify-content: space-between;
}

.account-timeline {
  max-width: 80%;
  margin: auto;
}
.account-timeline-user-name {
  padding: 10px;
}
.account-timeline-step {
  opacity: 0.2;
  align-items: center;
  padding-bottom: 2px;
}
button.sign-out-button {
  z-index: 10;
  align-self: flex-end;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

div.acct-sign-out {
}
p.account-timeline-step-text {
  align-self: baseline;
  justify-items: flex-end;
  font-size: 1.5vw;
  vertical-align: middle;
  font-weight: 400;
  margin-top: 8px;
  text-align: left;
}
.account-timeline-step-number {
  max-width: 3rem;
  width: 3rem;
  margin-right: 1.5rem;
  padding: 0;
}

#active-account-timeline-step {
  opacity: 1;
}

p.disabled-card-title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.disabled-card-img {
  height: 120px;
  margin-right: 1rem;
  box-shadow: -1px 1px 3px gray;
}

img.placeholder-block {
  height: 80px;
  width: 100%;
}

.account-page-c2 > * {
  width: 50%;
}

.account-title {
  display: none;
}
.mobile-welcome-card {
  display: none;
}

@media only screen and (max-width: 800px) {
  div.mobile-welcome-card {
    display: block;
  }
  div.account-page-c1-left {
    display: none;
  }
  div.account-page-c1-right {
    width: 100vw;
    overflow-x: hidden;
  }
  div.account-page-c1-right-content {
    width: 100vw;
    overflow-x: hidden;
  }
  div.account-page-c0 {
    width: 100vw;
    overflow-x: hidden;
  }

  div.account-page-c1-left-shadow {
    display: none;
  }
  .account-page-card-icon {
    margin-bottom: 10px;
  }

  .review-card-content.column-container {
    flex-direction: column;
  }
  .account-page-c2.column-container {
    flex-direction: column;
  }
  .referral-card-email-container {
    display: none;
  }
  .referral-card-btns-right {
    width: 100%;
  }
  .referral-card-btns-right.column-container {
    flex-direction: row;
  }
  .account-page-c2 > * {
    width: auto;
  }
  .placeholder-block {
    margin-top: 20px;
  }
}

