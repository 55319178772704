.account-page-c0 {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}
.account-page-c1-left {
  background-image: url("./../../images/bg/bg-gradient-third.svg");
  width: 30vw;
  min-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.account-page-c1-right {
  background-image: url("./../../images/bg/bg-pattern-full-light.svg");
  width: 70vw;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;

  padding-top: 2rem;
  padding-bottom: 2rem;
  justify-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.button-icon {
  padding-left: 5px;
}
h3.welcome-text {
  font-weight: 400;
  vertical-align: center;
}

h3.welcome-text > span > span {
  padding-bottom: 0.3rem;
}

.mobile-welcome-card-content {
  background-image: linear-gradient(to right, #5f5fc4, 50%, #001064);
  height: 100%;
  width: 100%;
}

.account-page-c1-left-shadow {
  position: absolute;
  min-height: 100vh;
  width: 30vw;
  z-index: 9;
  top: 50;
  left: 0;
  box-shadow: inset -2px 2px 4px black;
}
.account-page-c1-left-content {
  width: 90%;
  margin: auto;
  height: 100%;
  margin-bottom: 0;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.review-card {
  border: 1px solid #444e9e;
}
p.review-card-text {
  color: #444e9e;
  font-size: 0.9rem;
  line-height: 1.15rem;
}
.referral-card-email-container {
  width: 70%;
  margin-right: 10px;
}
.referral-card-email-field > *:last-child {
  height: 100%;
}
.referral-card-email-field {
  width: 65%;
}

.referral-card-content-text > span {
  line-height: 1rem;
  letter-spacing: 80%;
  margin-bottom: 2rem;
}
.referral-card-btn-container {
  height: 50px;
}
.referral-card-btns-right {
  width: 40%;
  margin-left: 0;
  justify-content: space-between;
}

h3.welcome-text {
  margin-bottom: 2em;
  z-index: 10;
}
.account-page-c1-right-content {
  width: 80%;
  margin: auto;
  justify-content: space-between;
}
.account-page-card-icon {
  height: 40px;
  width: 40px;
  margin-right: 2rem;
}

.placeholder-text {
  width: 100%;
}

.account-page-card {
  box-shadow: none;
  border: 1px solid #c4c4c4;
  margin-left: 0.8rem;
  margin-right: 1rem;
  margin-bottom: 0.75rem;
}
.referral-card-content {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.review-card {
  border: 1px solid #444e9e;
}
.disabled-card-content {
  opacity: 0.2;
}

.referral-card-email-container {
  width: 70%;
  margin-right: 10px;
}
.referral-card-email-field > *:last-child {
  height: 100%;
}
.referral-card-email-field {
  width: 65%;
}

button > * > img {
  padding: 10px;
}

button.referral-button {
  border: 1px solid #ececec;

  width: 100%;
  margin-left: 2px;
}

button.send-invites-button {
  min-width: 35%;
  margin-left: 0.2rem;
}
.referral-card-content-text > span {
  line-height: 1rem;
  letter-spacing: 80%;
  margin-bottom: 2rem;
}
.referral-card-btn-container {
  height: 50px;
}
.referral-card-btns-right {
  width: 40%;
  margin-left: 0;
  justify-content: space-between;
}

.account-timeline {
  max-width: 80%;
  margin: auto;
}
.account-timeline-user-name {
  padding: 10px;
}
.account-timeline-step {
  opacity: 0.2;
  align-items: center;
  padding-bottom: 2px;
}
button.sign-out-button {
  z-index: 10;
  align-self: flex-end;
  padding-bottom: 1rem;
  padding-right: 1rem;
}

div.acct-sign-out {
}
p.account-timeline-step-text {
  align-self: baseline;
  justify-items: flex-end;
  font-size: 1.5vw;
  vertical-align: middle;
  font-weight: 400;
  margin-top: 8px;
  text-align: left;
}
.account-timeline-step-number {
  max-width: 3rem;
  width: 3rem;
  margin-right: 1.5rem;
  padding: 0;
}

#active-account-timeline-step {
  opacity: 1;
}

p.disabled-card-title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.disabled-card-img {
  height: 120px;
  margin-right: 1rem;
  box-shadow: -1px 1px 3px gray;
}

img.placeholder-block {
  height: 80px;
  width: 100%;
}

.account-page-c2 > * {
  width: 50%;
}

.account-title {
  display: none;
}
.mobile-welcome-card {
  display: none;
}

@media only screen and (max-width: 800px) {
  div.mobile-welcome-card {
    display: block;
  }
  div.account-page-c1-left {
    display: none;
  }
  div.account-page-c1-right {
    width: 100vw;
    overflow-x: hidden;
  }
  div.account-page-c1-right-content {
    width: 100vw;
    overflow-x: hidden;
  }
  div.account-page-c0 {
    width: 100vw;
    overflow-x: hidden;
  }

  div.account-page-c1-left-shadow {
    display: none;
  }
  .account-page-card-icon {
    margin-bottom: 10px;
  }

  .review-card-content.column-container {
    flex-direction: column;
  }
  .account-page-c2.column-container {
    flex-direction: column;
  }
  .referral-card-email-container {
    display: none;
  }
  .referral-card-btns-right {
    width: 100%;
  }
  .referral-card-btns-right.column-container {
    flex-direction: row;
  }
  .account-page-c2 > * {
    width: auto;
  }
  .placeholder-block {
    margin-top: 20px;
  }
}
