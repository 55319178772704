@import url("https://fonts.googleapis.com/css2?family=Oxygen&family=Lato&family=Vollkorn&display=swap");
.join-page-c1-left {
  width: 50vw;
  background-image: url("./../../images/bg/bg-pattern-half.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.join-page-c1-right {
  width: 50%;
  background-color: #f6f6f6;
  height: auto;
  padding-top: 75px;
}

.join-page-c0 {
  width: 100vw;
  min-height: 100vh;
  margin-top: -50px;
}
.join-page-c1-right-content {
  width: 90%;
  max-width: 420px;
  margin: auto;
}

div.join-form > div.form-item-container > div.form-item-text-field {
  padding-left: 0px !important;
}

.join-page-c1-left-shadow {
  position: absolute;
  width: 50vw;
  z-index: 9;
  top: 50;
  left: 0;
  box-shadow: inset -2px 2px 4px black;
}
span.join-disclaimer-text {
  float: bottom left;
  margin-bottom: 2rem;
  margin-left: 2rem;
}
.join-loading-subtitle > span {
  font-size: 1.5rem;
  font-family: [ "Lato", "sans-serif" ];
}

.join-loading-subtitle {
  margin-top: 2rem;
  font-size: 1.5rem;
}

.join-form > button {
  margin-bottom: 1rem;
}

.join-or-horizontal-line {
  height: 1px;
  width: 100%;
  background-color: #c6cacc;
  vertical-align: center;
  align-self: center;
  justify-items: center;
  margin: auto;
}

span.join-or {
  display: flex;
  font-weight: bold;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
h2.form-container {
  padding-top: 30px;
}
.join-or-container {
  margin-bottom: 1rem;
}

button.google-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}
button.apple-sign-button {
  background-color: white;
  border: 1px solid #d1d3d4;
  color: #283593;
}

span.join-phone-explainer {
  position: relative;
  top: -2rem;
  font-weight: 500;
  color: #6d6d6d;
  font-size: 0.75rem;
}
.join-form > .early-input {
  padding-bottom: 1rem;
}
.join-form > .form-item-container {
  padding-bottom: 2rem;
}

@media only screen and (max-width: 800px) {
  .join-page-c1-left {
    display: none;
  }
  .join-page-c1-right {
    width: 100%;
  }
  .join-page-c1-left-shadow {
    display: none;
  }
  h2.teal-highlight {
    margin-left: 20px;
  }
}

button.google-sign-button {
  margin-bottom: 1.4rem;
}

span.join-sign-in-button {
  text-decoration: underline;
  color: #283593;
  font-weight: 600;
}

p.join-sign-in-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.9rem;
}
