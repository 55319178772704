@import url("https://fonts.googleapis.com/css2?family=Crimson+Text&display=swap");
.header-c0 {
  background-color: #283596;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 3px;
  top: 0;
  position: relative;
  width: 100vw;
  z-index: 100;
}
.header-logo {
  margin-top: 10px;
  height: 50px;
  width: auto;
  margin-left: 2vw;
}

.header-button-container {
  height: 100%;
  display: flex; 
  align-items: center;
}

.header-button-container > button{
  height: 60%;
  margin-right: 2vw;
  padding-left: 30px;
  padding-right: 30px;
}

.username-button {
  border: none !important;
  font-size: 15pt !important;
}
.contact-button {
  border: none !important;
}
@media only screen and (max-width: 750px) {
  .header-button-container > button{
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 750px) {
  .header-button-container > button{
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
    font-size: 10pt !important;
  }
  .header-sign-out-button{
    display: none !important;
  }
}
