.outer-container {
  width: 80vw;
  padding: 5rem;
  margin-top: 10vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-self: center;
}

@media only screen and (max-width: 750px) {
  #outer-container-mobile {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
.root {
  background-color: #f6f6f6;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 10vh;
  background-color: #f6f6f6;
  margin-bottom: 5vh;
}

.underline-highlight {
  border-bottom: solid 10px #7282d3;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}
