h1.refund-amount {
  background: linear-gradient(to right, #5f5fc4, 50%, #001064);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 8vw;
}
h6.onboard-complete-title {
  background: linear-gradient(to right, #5f5fc4, 50%, #001064);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2vw;
}

button.onboard-complete-apply-button {
  background-image: linear-gradient(to right, #5f5fc4, 50%, #001064);
  color: white;
  font-size: 2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 5rem;
  width: 70%;
}
.onboard-complete-c1-breakdown {
  width: 60%;
  justify-content: flex-start;
}
.onboard-complete-c1-content {
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: space-between;
}
h6.onboard-complete-apply-text {
  width: 70%;
  font-size: 1rem;
  text-align: center;
}

.onboard-complete-c1 {
  width: 70%;
  margin: auto;
  margin-bottom: 4rem;
  padding-bottom: 6rem;
  background-color: #f6f6f6;
  box-shadow: -0.3rem 0.3rem 2rem black;
  margin-top: 8rem;
  padding-top: 5rem;
  text-align: center;
}

p.refund-tooltip {
  font-size: 1rem;
}

.onboard-complete-c0-top {
  margin: auto;
  background-image: url("./../../images/bg/bg-pattern-double.svg");
  background-size: cover;
  background-position: top;
  display: flex;
  z-index: -2;
}
.onboard-complete-c0-bottom {
  margin: auto;
  text-align: center;
  background-color: #f6f6f6;
  width: 100%;
}
.onboard-complete-c0-bottom-content {
  max-width: 55%;
  margin: auto;
  padding-bottom: 5rem;
}

p.refund-breakdown-row-amount {
  font-weight: 100;
  width: 100%;
  font-size: 2rem;
}

p.refund-label-row {
  font-weight: 1000;
  font-size: 2em;
  width: 100%;
}
.refund-breakdown-row-c0 {
  width: 100%;
  text-align: right;
  margin-bottom: 1.5rem;
  z-index: 9;
}

a.refund-breakdown-row-label {
  padding-top: 0.3rem;
  text-decoration: underline;
  z-index: 999;
}

.refund-breakdown-row-c1 {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #4056a1;
}
.refund-breakdown-c0 {
  width: 50%;
  margin: auto;
  margin-top: 6rem;
}
.onboard-complete-card-1-content {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, #5f5fc4, 50%, #001064);
}
.refund-card-button-text {
  vertical-align: middle;
}

h4.refund-card-button-text {
  font-weight: 500;
  text-align: center;
  align-self: center;
  font-size: 1.8vw;
}
p.refund-card-button-subtext {
  padding-top: 1rem;
  font-size: 1vw;
  text-align: left;
  align-self: center;
}

.onboard-complete-card-3-content {
  width: 100%;
  background-image: url("./../../images/refund/card-3.svg");
  background-color: #f6f6f6;
  background-repeat: none;
  background-position: fit;
}

.onboard-complete-card-2-content {
  width: 100%;
  background-image: url("./../../images/refund/card-2.svg");
  background-color: #f6f6f6;
  background-repeat: none;
  background-position: fit;
}
div.refund-card-text {
  padding-top: 2rem;
}
.onboard-complete-card {
  display: flex;
  max-width: 300px;
  height: 200px;
  margin-bottom: 2rem;
  margin-right: 0.5rem;
  border: 1px solid #c3c3c3;
}

div.onboard-complete-card-container {
  width: 40%;
  min-height: 100%;
  justify-content: flex-start;
  z-index: 10;
}
h6.onboard-complete-fee-text {
  margin-top: 1rem;
  line-height: 120%;
}
.onboard-complete-fee-text-container {
  max-width: 80%;
  text-align: center;
  margin: auto;
}

.onboard-complete-help-button-container {
  height: 100vh;
  width: 20vw;
  z-index: 2;

  position: absolute;
  top: 0px;
  left: 80vw;
}
.onboard-complete-help-button {
  margin: auto;
  position: absolute;
  bottom: 20vh;
  left: 0%;
  border-radius: 50%;
  width: 20vw;
  height: 20vw;
  align-items: center;
}
.onboard-complete-card-mobile {
  display: none;
}

h6.onboard-complete-help-text {
  /* background: linear-gradient(to right, #5f5fc4, 50%, #001064); */
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding-bottom: 10px;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  padding-top: 50px;
}

img.onboard-complete-help-arrow {
  width: 75px;
  cursor: pointer;
}
.refund-subtext-mobile {
  display: none;
}

.refund-breakdown-highlight-red > *:first-child > *:first-child > *:last-child {
  background-image: url("./../../images/text-decoration/red-highlight.svg");
  background-position: right;
  background-repeat: no-repeat;
}
.refund-breakdown-highlight-green
  > *:first-child
  > *:first-child
  > *:last-child {
  background-image: url("./../../images/text-decoration/green-highlight.svg");
  background-position: right;
  background-repeat: no-repeat;
}

.refund-card-button-icon {
  height: 3vh !important;
  align-self: center;
  color: white;
}

.onboard-complete-footer {
  width: 100%;
  background-color: #f6f6f6;
  vertical-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
}

@media only screen and (max-width: 750px) {
  .onboard-complete-c1 {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: none;
    padding-bottom: 0px;
  }
  .refund-breakdown-c0 {
    margin-top: 4rem;
  }
  div.onboard-complete-card-1-content {
    width: auto;
    height: auto;
    font-size: 3.5vw;
    padding: 16px !important;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
  }
  .onboard-complete-card-2-content {
    width: auto;
    background-size: cover;
    height: 200px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .onboard-complete-card-3-content {
    background-image: none;
    max-width: 300px;
    width: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .refund-card-button-subtext-mobile {
    width: 100%;
    justify-items: center;
    display: flex;
  }
  p.refund-card-button-subtext-mobile {
    width: 80%;
    margin: auto;
    padding-top: 1.5rem;

    font-size: 1rem;
  }
  h4.refund-card-button-text {
    font-size: 3vw;
  }
  
  .onboard-complete-help-button-container {
    display: block;
  }
  .onboard-complete-footer {
    background-color: #ffffff;
  }
  h1.refund-amount {
    font-size: 4rem;
    padding-bottom: 3rem;
  }
  .refund-breakdown-row-c1.column-container {
    flex-direction: row;
  }
  .onboard-complete-apply-button {
    font-size: 4rem;
    padding: 0.5rem;
    margin-top: 3rem;
    height: auto;
  }
  h6.onboard-complete-title {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 5vw;
    padding-top: 3rem;
  }
  p.refund-card-button-subtext {
    display: none;
  }
  .help-button-icon {
    max-height: 1rem;
    align-self: center;
    margin-bottom: -0.1rem;
    padding: 0px;
  }
  .refund-breakdown-c0 {
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0rem;
  }
  .onboard-complete-card-mobile {
    display: block;
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    background-color: #f6f6f6 !important;
  }
  .onboard-complete-c1-breakdown {
    width: 100%;
  }

  .onboard-complete-card-container {
    width: 0px;
    display: none;
  }
  .onboard-complete-footer {
    display: none;
  }
}

.refund-card-button-content {
  max-width: 90%;
}
