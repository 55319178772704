.process-c0 {
  background-color: #f6f6f6;
  width: 100vw;
  height: 100vh;
}
.process-c1 {
  margin: auto;
  text-align: center;
  height: 75%;
  width: 80%;
}
.process-card {
  margin: auto;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.process-card-text {
  width: 50%;
}
h2.process-title {
  margin-bottom: 3rem;
}
